<template>
  <b-form-group label="Islamic Org">
    <v-select
      id="Islamic Org"
      :value="inpoId"
      :options="entities"
      :reduce="tag => tag.id"
      label="name"
      :disabled="isDisabled"
      @input="setInpoID"
    >
      <template #search="{attributes, events}">
        <input
          class="vs__search"
          v-bind="attributes"
          v-on="events"
        >
      </template>
    </v-select>
  </b-form-group>
</template>

<script>
import vSelect from 'vue-select'

export default {
  name: 'InpoSelect',
  components: {
    vSelect,
  },
  props: {
    inpoId: { type: Number, default: null },
    isDisabled: { type: Boolean, default: false },
  },
  data() {
    return {
      entities: [],
    }
  },
  created() {
    this.getInpoLinkedWithStripe()
  },
  methods: {
    getInpoLinkedWithStripe() {
      this.$entities.get('internalops/entities/get_stripe_inpo').then(res => {
        this.entities = res.data.data
      })
    },
    setInpoID(id) {
      this.$emit('update:inpo-id', id)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
